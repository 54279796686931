// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './SurveyForm.css';  // Importing the custom CSS file
// import img11 from '../img/img4.webp';

// function SurveyForm() {
//   const [formData, setFormData] = useState({ radioQuestion: '' });
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [voteCounts, setVoteCounts] = useState({});
//   const navigate = useNavigate();

//   const candidates = [
//     { name: 'बाळासाहेब थोरात (महाविकास आघाडी)', value: 'Balasaheb Thorat' },
//     { name: 'अमोल खताळ (महायुती)', value: 'Amol Khatal' },
//     { name: 'योगेश सूर्यवंशी (मनसे)', value: 'Yogesh Suryavanshi' },
//     { name: 'अजीज वोहरा (वंचित बहुजन आघाडी)', value: 'Aziz Vohra' },
//     { name: 'अविनाश भोर (जय हिंद जय भारत राष्ट्रीय पार्टी)', value: 'Avinash Bhor' }
//   ];

//   useEffect(() => {
//     // Fetch initial vote counts when the page loads
//     axios.get('http://localhost:5000/api/survey/counts')
//       .then(response => setVoteCounts(response.data))
//       .catch(error => console.error('Error fetching vote counts:', error));
//   }, []);

//   const handleChange = (e) => {
//     setFormData({ ...formData, radioQuestion: e.target.value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (!formData.radioQuestion) {
//       alert('कृपया एक उमेदवार निवडा');
//       return;
//     }

//     setIsSubmitting(true);

//     // Post the vote and then fetch updated counts
//     axios.post('http://localhost:5000/api/survey', { candidate: formData.radioQuestion })
//       .then(() => {
//         alert('मत नोंदवले आहे!');
//         axios.get('http://localhost:5000/api/survey/counts')
//           .then(response => {
//             setVoteCounts(response.data);
//           })
//           .catch(error => console.error('Error fetching updated vote counts:', error));
//       })
//       .catch(error => {
//         console.error('Error submitting vote:', error);
//         alert('मत नोंदवण्यात अयशस्वी. कृपया पुन्हा प्रयत्न करा.');
//       })
//       .finally(() => setIsSubmitting(false));
//   };

//   const handleViewResults = () => {
//     // When the View Results button is clicked, pass voteCounts to the results page
//     navigate('/results', { state: { voteCounts } });

//     // Show results for 3 seconds, then redirect to the new URL
//     setTimeout(() => {
//       window.location.href = 'https://uniquemarathinews.com/';
//     }, 3000); // Redirects after 3 seconds
//   };

//   return (
//     <div className="survey-form-container">
//       <header className="header py-3">
//         <div className="container text-center">
//           <img src={img11} alt="Election Banner" className="header-image img-fluid mb-3" />
//           <h2 className="header-title">2024 च्या विधानसभा निवडणुकीत कोण निवडून येणार कोण असेल आपल्या पसंतीचा मनातील उमेदवार?</h2>
//         </div>
//       </header>

//       <div className="container mt-3">
//         <form onSubmit={handleSubmit} className="shadow p-4 rounded">
//           <div className="form-group">
//             <label className="form-label">एक उमेदवार निवडा:</label>
//             {candidates.map((candidate) => (
//               <div key={candidate.value} className="form-check">
//                 <input
//                   type="radio"
//                   className="form-check-input"
//                   name="radioQuestion"
//                   value={candidate.value}
//                   onChange={handleChange}
//                   required
//                 />
//                 <label className="form-check-label">{candidate.name}</label>
//               </div>
//             ))}
//           </div>

//           <div className="d-flex justify-content-between mt-3">
//             <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
//               {isSubmitting ? 'प्रस्ताव सबमिट करत आहोत...' : 'मत नोंदवा'}
//             </button>

//             <button
//               type="button"
//               className="btn btn-secondary ms-3"
//               onClick={handleViewResults}>
//               निकाल पहा
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default SurveyForm;






import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SurveyForm.css';  // Importing the custom CSS file
import img11 from '../img/img4.webp';

function SurveyForm() {
  const [formData, setFormData] = useState({ radioQuestion: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [voteCounts, setVoteCounts] = useState({});
  const navigate = useNavigate();

  const candidates = [
    { name: 'बाळासाहेब थोरात (महाविकास आघाडी)', value: 'Balasaheb Thorat' },
    { name: 'अमोल खताळ (महायुती)', value: 'Amol Khatal' },
    { name: 'योगेश सूर्यवंशी (मनसे)', value: 'Yogesh Suryavanshi' },
    { name: 'अजीज वोहरा (वंचित बहुजन आघाडी)', value: 'Aziz Vohra' },
    { name: 'अविनाश भोर (जय हिंद जय भारत राष्ट्रीय पार्टी)', value: 'Avinash Bhor' }
  ];

  useEffect(() => {
    // Fetch initial vote counts when the page loads
    axios.get('https://survey.printforu.in/api/survey/counts')
      .then(response => setVoteCounts(response.data))
      .catch(error => console.error('Error fetching vote counts:', error));
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, radioQuestion: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.radioQuestion) {
      alert('कृपया एक उमेदवार निवडा');
      return;
    }

    setIsSubmitting(true);

    // Post the vote and then fetch updated counts
    axios.post('https://survey.printforu.in/api/survey', { candidate: formData.radioQuestion })
      .then(() => {
        alert('मत नोंदवले आहे!');
        axios.get('https://survey.printforu.in/api/survey/counts')
          .then(response => {
            setVoteCounts(response.data);
          })
          .catch(error => console.error('Error fetching updated vote counts:', error));
      })
      .catch(error => {
        console.error('Error submitting vote:', error);
        alert('मत नोंदवण्यात अयशस्वी. कृपया पुन्हा प्रयत्न करा.');
      })
      .finally(() => setIsSubmitting(false));

    // After form submission, automatically redirect to results page
    navigate('/results', { state: { voteCounts } });

    // After redirecting, wait 3 seconds to navigate to homepage
    setTimeout(() => {
      window.location.href = "https://bizkranti.com/"; // Redirect after 3 seconds
    }, 3000);
  };

  const handleSocialShare = (platform) => {
    const url = 'https://survey.printforu.in/';  // Fixed URL for sharing
    const text = 'Participate in the election survey!'; // Custom message to share
    const imageUrl = 'https://survey.printforu.in/img/img4.webp'; // Image URL for sharing (replace with your actual image URL)

    let shareUrl = '';
    
    if (platform === 'facebook') {
      // For Facebook, we add the image in the 'picture' field
      shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(text)}&picture=${encodeURIComponent(imageUrl)}`;
    } else if (platform === 'twitter') {
      // For Twitter, the image URL will appear in the preview, depending on how Twitter handles the URL metadata
      shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}&via=${encodeURIComponent('your-twitter-handle')}`;
    } else if (platform === 'whatsapp') {
      // WhatsApp doesn't support image URLs in text, but the preview image will show if it's a valid link
      shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)} ${encodeURIComponent(url)}`;
    }

    window.open(shareUrl, '_blank', 'width=600,height=400');
  };

  return (
    <div className="survey-form-container">
      <header className="header py-3">
        <div className="container text-center">
          <img src={img11} alt="Election Banner" className="header-image img-fluid mb-3" />
          <h2 className="header-title">2024 च्या विधानसभा निवडणुकीत कोण निवडून येणार कोण असेल आपल्या पसंतीचा मनातील उमेदवार?</h2>
        </div>
      </header>

      <div className="container mt-1">
        <form onSubmit={handleSubmit} className="shadow p-4 rounded">
          <div className="form-group">
            <label className="form-label">एक उमेदवार निवडा:</label>
            {candidates.map((candidate) => (
              <div key={candidate.value} className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="radioQuestion"
                  value={candidate.value}
                  onChange={handleChange}
                  required
                />
                <label className="form-check-label">{candidate.name}</label>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? 'प्रस्ताव सबमिट करत आहोत...' : 'मत नोंदवा'}
            </button>
          </div>
        </form>

        <div className="social-media-share mt-2 mb-5 text-center">
          <div className="social-icons">
            <button className="btn btn-outline-primary mx-2" onClick={() => handleSocialShare('facebook')}>
              <i className="fab fa-facebook-f"></i> Facebook
            </button>
            <button className="btn btn-outline-info mx-2" onClick={() => handleSocialShare('twitter')}>
              <i className="fab fa-twitter"></i> Twitter
            </button>
            <button className="btn btn-outline-success mx-2" onClick={() => handleSocialShare('whatsapp')}>
              <i className="fab fa-whatsapp"></i> WhatsApp
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyForm;







