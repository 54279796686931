import React from 'react';
import { useLocation } from 'react-router-dom';

function ResultsPage() {
  const location = useLocation();
  const voteCounts = location.state?.voteCounts || {};

  if (!Object.keys(voteCounts).length) {
    return <p>Loading results...</p>;
  }

  return (
    <div className="container mt-5">
      <h2 className="text-center">Voting Results</h2>
      <div>
        {Object.keys(voteCounts).map((candidate) => (
          <div key={candidate} className="mb-3">
            <h5>{candidate}:</h5>
            <p>{voteCounts[candidate]} votes</p>
            <div className="progress">
              <div className="progress-bar" role="progressbar" style={{ width: `${(voteCounts[candidate] / 14) * 100}%` }}>
                {voteCounts[candidate]} votes
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ResultsPage;
